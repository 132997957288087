import {
  Carousel as MoonCarousel,
  mergeClassnames,
} from '@heathmont/moon-core-tw';
import useAgentData from '~/client/hooks/useAgentData';
import CarouselButton from './CarouselButton';
import CarouselContent from './CarouselContent';

type Props = {
  items?: ItemsReturnType;
  step?: number;
  scrollTo?: number;
  reelClassName?: string;
  scrollToLeftButton?: JSX.Element | null;
  scrollToRightButton?: JSX.Element | null;
  isNotScrollable?: boolean;
  withIndicators?: boolean;
};

const Carousel = ({
  items,
  step,
  scrollTo,
  reelClassName,
  isNotScrollable,
  scrollToLeftButton = <CarouselButton direction="left" />,
  scrollToRightButton = <CarouselButton direction="right" />,
  withIndicators,
}: Props) => {
  const { isDesktop } = useAgentData();
  if (isNotScrollable) {
    return (
      <div data-testid="carousel-wrapper">
        <MoonCarousel step={step} scrollTo={scrollTo}>
          <MoonCarousel.Reel
            className={mergeClassnames(
              'gap-3',
              !isDesktop && '-mx-5 px-5',
              reelClassName,
            )}
          >
            <CarouselContent items={items} />
          </MoonCarousel.Reel>
          {withIndicators && (
            <MoonCarousel.Indicators className="relative bottom-0 mt-4 justify-center" />
          )}
        </MoonCarousel>
      </div>
    );
  }

  return (
    <div data-testid="carousel-wrapper">
      <MoonCarousel step={step} scrollTo={scrollTo}>
        {scrollToLeftButton}
        <MoonCarousel.Reel
          className={mergeClassnames(
            'gap-3 scroll-hidden',
            !isDesktop && '-mx-5 px-5 rtl:flex-row',
            reelClassName,
          )}
        >
          <CarouselContent items={items} />
        </MoonCarousel.Reel>
        {scrollToRightButton}
        {withIndicators && (
          <MoonCarousel.Indicators className="relative bottom-0 mt-4 justify-center" />
        )}
      </MoonCarousel>
    </div>
  );
};

export default Carousel;
